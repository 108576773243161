<template>
  <div class="card flex-container">
    <div style="padding: 20px">
    <p class="paragraph" v-for="(evaluation, index) in evaluations" :key="index" :evaluation="evaluation">{{ evaluation }}</p>
    </div>
    <!-- <div style="min-width: 300px; height: 280px; flex-grow: 0; margin: 20px 60px 20px 0">
        <div id="chart" style="width: 120%; height: 280px;"/>
    </div>
    -->
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { TitleComponent, LegendComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

export default {
  name: 'Evaluation',
  props: {
    evaluations: []
  },
  mounted: function() {

/**
var myChart = echarts.init(document.getElementById("chart"));
var option;

option = {
  radar: {
    // shape: 'circle',
    indicator: [
      { name: '沟通协作', max: 100 },
      { name: '学习能力', max: 100 },
      { name: '项目经验', max: 100 },
      { name: '抗压能力', max: 100 },
      { name: '探索创新', max: 100 },
      { name: '专业技能', max: 100 },
      { name: '执行力', max: 100 },
      { name: '架构能力', max: 100 }
    ]
  },
  series: [
    {
      name: 'Budget vs spending',
      type: 'radar',
      
      data: [
        {
          value: [90, 95, 94, 92, 96, 98, 96, 95],
          name: 'Allocated Budget'
        }
      ]
    }
  ]
};

option && myChart.setOption(option);
**/
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
