<template>
    <div style="height:1px; width: 100%; border-bottom: 1px dashed #6F6F6F; margin: 10px auto;"></div>


    <div class="title-frame">
        <div class="title"><i :class="['fa', icon]">&nbsp;{{ name }}</i></div>
    </div>
</template>

<script>
export default {
  name: 'Divider',
  props: {
    name: String,
    icon: String
  }
}
</script>

<style scoped>
    .title-frame {

    }

    .title {
        
        padding: 10px 20px;
        font-size: 25px;
        font-weight: bold;
        display: inline-block;
        
        border-radius: 8px 0 8px 0;
        
    }
</style>
