<template>
  <div class="card">
    <div v-for="(item, index) in companies" :key="index" :item="item" class="flex-container">
    <img class="flex-item" :src="item.logo" style="height: 40px; padding-right: 0; border-radius: 8px" />
    
    <div class="flex-item" style="flex-basis: 320px; flex-grow: 0">{{ item.name }}</div>
    <div class="flex-item">{{ item.title }}</div>
    <div class="flex-item">{{ item.peroid }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Company',
  props: {
    companies: []
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.flex-container {display: flex;padding-bottom: 0;}
/* 以下为辅助样式 */.flex-container{}
.flex-container .flex-item{margin: 20px 20px 0 20px; line-height: 40px}
.flex-item {flex-grow: 1; text-align: left; font-size: 22px;}
.flex-container .flex-item:first-child{flex-grow: 0}
.flex-container .flex-item:last-child{text-align: right}
</style>
