<template>
    <div>
        <div class="flex-container">
            <div class="flex-item">
                <div class="full-name">
                    <div class="full-name-item">{{ info.name }}</div>
                    <div class="full-name-item">{{ info.brief }}</div>
                </div>
                <div class="personal-info">
                    <div class="container">
                        <div class="col" v-for="(item, index) in info.contact" v-bind:key="index">
                            <i :class="['fa', item.icon]">&nbsp;{{ item.text }}</i>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    info: {}
  }
}
</script>

<style scoped>

    .flex-container {
        
        display: flex;
    }

    .flex-item {
        flex-grow: 1;
        text-align: left;
    }

    .flex-container .flex-item:last-child {
        flex-grow: 0;
        text-align: right;
    }

    .full-name {
        height: 60px;
        margin-top: 10px;
        display: flex;
        align-items: flex-start;
    }

    .full-name-item {
        font-size: 18px;
        flex-grow: 1;
        text-align: right;
    }

    .full-name .full-name-item:first-child {
        
        text-align: left;
        font-size: 36px;
        padding: 0 20px;
        flex-basis: 20px;
        font-weight: bold;
    }

    #photo {
        width: 150px;
        height: 200px;
        border-radius: 8px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .col {
        flex: 1 0 40%;
        text-align: left;
        padding: 10px 0;
        margin: 0 20px;
    }

    i {
        font-size: 25px;
    }
</style>
