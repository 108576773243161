<template>
    <div class="curved_box">
        <Header :info="info"/>
        <!--
        <Divider icon="fa-graduation-cap" name="教育背景"/>
        <Education v-for="(item, index) in educations" :key="index" :item="item"/>
        -->
        <Divider icon="fa-briefcase" name="教育 & 工作经历"/>
        <Company :companies="companies"/>
        <Divider icon="fa-rocket" name="项目经验"/>
        <Project :projects="projects"/>
        <Divider icon="fa-code" name="职业技能"/>
        <Ability :abilities="abilities"/>
        <Divider icon="fa-tags" name="自我评价"/>
        <Evaluation :evaluations="evaluations"/>

    </div>
</template>

<script>

import Divider from './components/Divider.vue'

import Header from './components/Header.vue'
import Company from './components/Company.vue'
import Project from './components/Project.vue'
import Ability from './components/Ability.vue'
import Evaluation from './components/Evaluation.vue'

export default {
    name: 'App',
    components: {
        Divider,
        Header,
        Company,
        Project,
        Ability,
        Evaluation
    },
    data() {
        return {
        title: '李新宇',
        info: {
            'name': '李新宇',
            'brief': 'Java 软件开发工程师 | 工作年限 : ' + (new Date().getFullYear() - 2018) + ' 年 | 到岗时间 : 一个月内',
            'contact': [
                {
                    'icon': 'fa-phone',
                    'text': '13182827612'
                },
                {
                    'icon': 'fa-calendar',
                    'text': '1995 年 09 月'
                },
                {
                    'icon': 'fa-envelope',
                    'text': 'mail@omgxy.com'
                },
                {
                    'icon': 'fa-github',
                    'text': 'https://github.com/heartspade'
                }
            ],
            'photo': 'https://img.omgxy.com/imgs/2021/11/5975d7b8acc4779b.jpg'
        },
        educations: [
            {
                
            }
        ],
        companies: [
            {
                'logo': 'https://img.omgxy.com/imgs/2021/11/98571ee243de5ac5.jpg',
                'name': '安徽财经大学',
                'title': '计算机科学与技术',
                'peroid': '2014 年 09 月 - 2018 年 07 月'
            },
            {
                'logo': 'https://img.omgxy.com/imgs/2021/11/aadfe772c03ff16d.png',
                'name': '雷技信息科技 (上海) 有限公司',
                'title': 'Java 开发工程师',
                'peroid': '2018 年 07 月 - 2021 年 03 月'
            },
            {
                'logo': 'https://img.omgxy.com/imgs/2021/11/1f7ff78d7d5596f0.png',
                'name': '南京趣增长信息科技有限公司',
                'title': 'Java 开发工程师',
                'peroid': '2021 年 03 月 - 2022 年 01 月'
            },
            {
                'logo': 'https://img.omgxy.com/imgs/2023/08/cb2d04e36ebf34ee.jpg',
                'name': '江苏臻云技术有限公司',
                'title': 'Java 开发工程师',
                'peroid': '2022 年 02 月 - 至今'
            }
        ],
        projects: [
            {
                'name': 'EHS (环境、健康与安全) 合规管理系统 V12.0',
                'peroid': '2018 - 2020',
                'icon': 'https://img.omgxy.com/imgs/2022/02/54a4294a54a79642.png',
                'tags': ["Spring Boot", "MySQL", "Lucene", "RabbitMQ"],
                'summary': "该系统主要由化学品及物料管理、健康与安全管理、环境与监测管理等几大模块构成，帮助企业提高生产管理合规性。采用 Springboot + Angular 前后端分离框架开发，服务端使用了 Spring、Mybatis、 Redis、RocketMQ、Lucene、JWT、Thymeleaf、OKHttp 等技术方案 支持 MySQL、SQL Server 等主流数据库。",
                'sections': [
                    
                    {
                        "name": "解决了系统中的多处性能问题和安全漏洞",
                        "lines": [
                            "分析并定位系统中大量不良设计，如: 使用异步+多线程发送业务邮件，减少了关键接口的阻塞。改进系统文件上传模块，增加了阿里云 OSS 与七牛云以及 NFS 的实现，提高文件服务稳定性的同时，也减轻了业务系统的 IO 和带宽压力。将很多繁琐的 SQL 语句进行了优化，改良了数据库设计，使得系统整体性能提升了约 30%，平稳通过压力测试。修复了 SQL 注入、越权访问、XSS 攻击、弱密码等漏洞。",
                        ]
                    },
                    {
                        "name": "实现了一整套单点登录集成方案",
                        "lines": [
                            "在项目实施的过程中，负责对接了 OA、AD 域等统一登录平台，实现了从人力资源管理系统帐号同步、使 Quantum 与企业已有信息化系统高度融合。对接钉钉、企业微信、公众号等企业办公平台，使得部分简单流程如汇报、审批等得以方便地在手机上操作，并接入各平台的消息体系，增加了如短信、服务号模板消息、钉钉卡片消息、极光推送等推送渠道，确保相关人员在第一时间收到任务通知。",
                        ]
                    },
                    {
                        "name": "参与产品 SaaS 架构的初期技术调研和 MVP 版本的开发工作",
                        "lines": [
                            "主导了包括基本架构、数据隔离、应用接入等模块的开发，完成了项目底层架构的设计，租户数据的隔离以及若干核心业务模块的接入。重构了检查和事故等模块，提供自定义配置选项，租户可对部分流程进行定制和拓展。",
                        ]
                    }
                ],
                "achievement": "极大系统整体响应性能、稳定性和易用性，降低了推广和实施成本，新签约客户数增长保持在 20% 以上"
            },
            {
                'name': '客户资源管理系统',
                'peroid': '2021',
                'icon': 'https://img.omgxy.com/imgs/2022/02/a27d4a8af85e9670.png',
                'tags': ["Spring Boot", "MySQL", "Redis"],
                'summary': "该项目是为某农资销售公司开发的一款客户信息维护和农资产品销售管理系统, 分为小程序端和后台管理系统。",
                'sections': [
                    {
                        "name": "负责微信小程序端功能的设计和开发",
                        "lines": [
                            "小程序对养殖户、塘口、经销商的管理（拜访、销售、签约等）, 销售和客户等报表数据的统计。",
                            "负责多数据源设计, 对接 OA 系统 SQL Server 数据库实现员工信息同步。"
                        ]
                    },
                    {
                        "name": "负责该项目的日常维护升级和性能优化",
                        "lines": [
                            "负责热力图聚合算法的设计和实现。按照行政区划和地理坐标对地图上的业务数据进行聚合统计, 生成热力图数据。将包括小程序首页在内的多个页面响应时间从 3000 毫秒优化到 200毫秒以内。",
                            "改善不合理的数据库设计, 完善索引, 适当冗余, 优化慢查询, 使用 Redis 缓存热点数据, 提升了接口响应速度。",
                        ]
                    }
                ]
            },
            {
                'name': '医美门店运营管理系统',
                'peroid': '2021',
                'icon': 'https://img.omgxy.com/imgs/2022/02/c5c851dc3b0fcb32.png',
                'tags': ["Spring Boot", "Spring Clould", "MySQL", "Redis", "RocketMQ"],
                'summary': "基于 SaaS 模式的电商系统, 使用 Spring Boot 和微服务架构开发, 主要包括用户、商品、订单、支付等核心模块, 每个模块拆分成独立服务, 服务间通过 Feign 相互调用, 通过分库分表实现租户隔离。",
                'sections': [
                    {
                        "name": "负责后台管理系统的接口设计",
                        "lines": [
                            "采用数据库分库的方式进行租户数据隔离。服务端使用 Spring AOP 动态设置数据库进行 SQL 查询。",
                            "消息通知模块的开发, 包括短信、小程序订阅消息、公众号模板消息等。对接小红书爬虫, 获取小红书粉丝数和笔记、评论等, 实现小红书认证自动审核、小红书笔记内容同步。",
                        ]
                    },
                    {
                        "name": "负责商城、订单、支付模块开发",
                        "lines": [
                            "对接服务中台为总部和门店后台管理系统提供基础的商品和套餐管理，小程序端商城和个人中心的开发。对接收钱吧和微信支付实现商品的购买。"
                        ]
                    }
                ]
            },
            {
                'name': '安财精灵微信公众平台',
                'peroid': '2016 - 2017',
                'icon': 'https://img.omgxy.com/imgs/2022/02/48fe6240288cf079.jpeg',
                'tags': ["PHP", "ThinkPHP", "MySQL"],
                'summary': "安财精灵是一个校园生活公众号，通过封装和聚合校内教务和一卡通系统的常用功能，通过公众号菜单，为校内大学生提供包括教务（成绩、课程、评教等）查询、一卡通充值、宿舍购电、社交、二手市场和校园资讯等便捷服务",
                'sections': [
                    {
                        "name": "负责模拟登录各内网系统，进行数据交互，提供封装接口",
                        "lines": [
                            "使用 cURL 函数实现用户身份认证、帐号绑定、自动登录等功能，使用二值化算法识别验证码，使用 xpath 从返回的网页中获取数据并存储到数据库中，封装了大量实用功能的接口。并对接微信公众号接口实现关键词自动回复，基于后台消息发送回调接口开发了 “盖楼抽奖” 功能、批量调用评教接口实现一键评教功能等。"
                        ]
                    },
                    {
                        "name": "负责实名社区和二手交易平台的开发",
                        "lines": [
                            "基于已有的用户体系搭建学生实名社区，开发了发贴、点赞、留言、分享等功能。在社区系统中，单独开发了一个二手交易主题的板块，用户可以在这里发布和浏览闲置物品，进行线上沟通，线下交易。"
                        ]
                    }
                ],
                "achievement": "公众号关注数量实现了一倍的增长，达到了 3 万，日均活跃用户达到 5000 人以上"
            }
        ],
        abilities: [
            {
                'proficiency': "熟练",
                'list': ["Java", "Spring", "Spring Boot", "Spring Clould", "MySQL", "Git"]
            },
            {
                'proficiency': "掌握",
                'list': ["JVM", "设计模式", "消息中间件", "Junit", "Linux", "Python", "Docker"]
            },
            {
                'proficiency': "了解",
                'list': ["Redis", "JavaScript", "CSS", "Vue", "PHP", "Typescript"]
            }
        ],
        evaluations: [
            "积极承担具有挑战性的开发任务，有良好的文档编写和代码书写规范，能独立解决问题。",
            "具备良好的英语阅读和表达能力, 已通过大学生英语等级考试 CET-6，能够流畅地阅读和理解英文文档。"
        ]
        }
    },
    setup() {
        window.addEventListener('keypress', (e) => {
            if (e.key == 'p') {
                console.log('HelloWorld!!!!!!!!!!!');
                window.print();
            }
        });
    }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
}

body {
    background-color: #EBEBEB;
}
#app {
  font-family: Arial, "STHeiti", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  
}

.header {
  min-height: 200px;
}

.curved_box {
  width: 1100px;
  padding: 40px;
  margin: 25px auto;
  border-radius: 1px;
  /* display: inline-block; */
  /* *display: inline; */
  background-color: #fff;
  border: 1px solid #eee;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 60px rgba(0, 0, 0, 0.06) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
  position: relative;
  *zoom: 1;
}

.flex-container {
    display: flex;
    
    padding-bottom: 20px;
}

.card {
    padding-bottom: 20px;
}

.paragraph {
    margin-bottom: 10px;
    line-height: 30px;
    color: #616161;
    font-size: 20px;
}

@page {
    size: auto;
    margin: 50px;
}

@media print {
    .curved_box {
        margin: 0 auto;
        border: none;
        padding: 0 40px;
        box-shadow: none;
    }
}

@media screen and (max-width: 500px) {
    .curved_box {
        margin: 0 auto;
        border: none;
    }
}
</style>
