<template>

<div class="card container">
  <div class="line" v-for="(ability, index) in abilities" :key="index">
    <span class="proficiency">{{ ability.proficiency }}</span>
    <span class="ability-item" v-for="(item, index) in ability.list" :key="index">
        {{ item }}
    </span>
  </div>
</div>
</template>

<script>
export default {
  name: 'Ability',
  props: {
    abilities: []
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.container {
    padding: 20px;
}

.line {
    margin-bottom: 10px;
}

.ability-item {
    padding: 5px 15px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    border: 1px solid #c5dfe2;
    border-radius: 25px;
    margin: 0 5px;
    background-color: #f9f9f9;
    color: #2c3e50;

}

.proficiency {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    background-color: #065279;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 10px;
}
</style>
