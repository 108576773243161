<template>
  <div class="card header">

  <div v-for="(item, index) in projects" :key="index" :item="item">

    <div style="display: flex;">
      <img :src="item.icon" style="width: 60px; height: 60px; margin-right: 20px"/>

      <div>
      <div class="project-title">{{ item.name }} <span style="margin-left: 1rem; color: #666666; "><i class="fa fa-calendar-o" />&nbsp;{{item.peroid}}</span></div>

      <div style="margin: 20px 0">
          <span class="tag" v-for="(tag, index) in item.tags" :key="index"> {{ tag }}</span>
      </div>
      </div>
    </div>

    <p class="paragraph">{{item.summary}}</p>
    

    <div class="content" v-for="(section, index) in item.sections" :key="index">

      

      <p class="project-hilight"><i class="fa fa-circle-o "/>&nbsp;{{ section.name }}</p>
      <p class="paragraph" v-for="(line, index) in section.lines" :key="index"> {{ line }}</p>
    </div>

    
    <div v-if="index!==projects.length-1" style="height:1px; width: 100%; border-bottom: 1px dashed #6F6F6F; margin: 40px auto;"></div>

    </div>

    
  </div>
</template>

<script>
export default {
  name: 'Project',
  props: {
    projects: []
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.header {
    padding: 20px;
}

.project-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.tag {
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 5px 15px;
    margin-right: 10px;
    border: 1px solid #c5dfe2;
}



.achievement {
  background-color: #F9F9F9;
  border: 1px solid #C5DFE2;
  border-radius: 5px;
  padding: 0.5rem 1rem;

}

.project-hilight {
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}

</style>
